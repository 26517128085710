import axios from "axios"
import { callMsGraph } from "../../MsGraphApiCall";

class HttpService {
  service: any;
  constructor(apiUrl: any) {
    this.service = axios.create({
      baseURL: apiUrl,
    })

    this.service.interceptors.request.use(async (req: { headers: { Authorization: string } }) =>{
      let bearer = await callMsGraph();
      req.headers.Authorization =  bearer;
     return req;
    });

    this.service.interceptors.response.use(undefined, this.handleError);
  }

  handleError(error: { response: {
    data(data: any): unknown; status: any 
  } }) {
    const statusCode = error.response?.status

    if (statusCode && statusCode !== 401) {
      errorUtils.lastErrorMessage = JSON.stringify(error.response.data);
    }

    return Promise.reject(error)
  }
}

export const RefApi = new HttpService(process.env.REACT_APP_REF_API_URL).service;
export const LinnaeApi = new HttpService(process.env.REACT_APP_LINNAE_API_URL).service;
export const LinnaeUrl = new HttpService(process.env.REACT_APP_LINNAE_URL).service;

export const errorUtils = {
  lastErrorMessage: ""
};

import Typography from '@mui/material/Typography';
import { useState, useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import { StoreFile } from '../../hooks/store';
import { fillArray, TableCSV } from './Table';
import Papa from 'papaparse';
import languageEncoding from "detect-file-encoding-and-language"

/**
 * ImporterCSV component allows users to import a CSV file, parse its content,
 * and display the parsed data in a table format. It uses the react-dropzone
 * library for file drop functionality and PapaParse for CSV parsing.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setFile - Function to set the file content.
 *
 * @example
 * <ImporterCSV setFile={setFile} />
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @remarks
 * - The component checks if the CSV file contains a column named "scientificName".
 * - If the column is not found, an error message is displayed.
 * - If the column is found, the CSV content is parsed and displayed in a table.
 * - The component also checks for semicolons in the scientific names and displays an error if any are found.
 */

export default function ImporterCSV({ setFile }: any) {

  const [errorHeader, setErrorHeader] = useState(false);
  const [errorParsing, setErrorParsing] = useState(false);
  const [indexError, setIndexError] = useState(0);
  const [table, setTable] = useState(false);
  const [fileName, setFileName] = useState<File>();

  const fileReader = new FileReader();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    var fileInit:File = acceptedFiles[0];

    setFileName(fileInit);
    setFile(fileInit);
    if (fileInit) {
      fileReader.onload = function (event) {
      const text = event.target?.result as string;

      let regexHeader = /scientificName/g;
      let matchHeader = text.match(regexHeader);
      matchHeader === null ? setErrorHeader(true) : setErrorHeader(false);
      if (text) {
        setErrorParsing(false);
        csvFileToArray(text);
        setTable(true);
      } else {
        setTable(false);
      }
    };

    languageEncoding(fileInit).then((fileInfo) => {
      const encoding = fileInfo.encoding === null ? 'windows-1252' : fileInfo.encoding;
      fileReader.readAsText(fileInit, encoding);
    });

  }
  }, [])

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({accept: {'text/csv*': [".csv"]}, onDrop});
  
  const csvFileToArray = (string: string) : Array<{ scientificName: string }> => {
    
    const parseResult = Papa.parse(string, {
      header: true,
      delimiter: ';',
      quoteChar: '\'',
      skipEmptyLines: true
    });
  
    if (parseResult.errors.length > 0) {
      console.error('Erreurs de parsing:', parseResult.errors);
    }

    const arrayTable = parseResult.data.filter((value: any, index: number, self: any) =>
      index === self.findIndex((t: any) => (
        t.scientificName === value.scientificName
      ))
    ).map((row: any, index: number) => ({
      id: index + 1,
      scientificName: row.scientificName
    }));

    const csvString = [
      [
        "scientificName"
      ],
      ...arrayTable.map((i :any) => [
        i.scientificName,
      ])
    ].join("\n");

    let regexSemicoma = /;/g;
    const lines = csvString.split('\n');
    lines.map((line, index) => {
      index = index + 1;
      if (regexSemicoma.test(line)) {
        setErrorParsing(true);
        setIndexError(index);
      }
    });

    setFile(csvString);
    fillArray(arrayTable);
    StoreFile.setState({isFinished: true});
    return arrayTable
  };

  return (
    <div className="container">
      <div {...getRootProps(
        {style :
          {display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          borderWidth: 2,
          color: '#808080',
          borderColor: '#bdbdbd',
          borderStyle: 'dashed',
          outline: 'none',}
        })}>
        <input {...getInputProps()}/>
        {!fileName && (
          <p>
            Déposez ou cliquez pour importer votre fichier CSV
          </p>
        )}
        {fileName && (
          <p>{fileName.name}</p>
        )}
      </div>

      {errorHeader && (
        <Typography sx={{ mt: 2, mb: 1, color: "red" }}>
          Fichier rejeté : La colonne "scientificName" n'a pas été trouvée
        </Typography>
      )}
      {errorParsing && (
        <Typography sx={{ mt: 2, mb: 1, color: "red" }}>
          Fichier rejeté : Le nom scientifique de la ligne {indexError} contient un point virgule
        </Typography>
      )}
      {table && !errorHeader && !errorParsing && (
        <TableCSV />
      )}
    </div>
  );
}
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { StoreRef, StoreTaxaVersion } from '../../hooks/store';

/**
 * SelectList component allows users to select a taxonomy reference from a dropdown list.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setReferentiel - Function to set the selected reference.
 * 
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * <SelectList setReferentiel={setReferentielFunction} />
 * 
 * @remarks
 * This component uses the `StoreRef` hook to fetch and filter taxonomy references.
 * It displays a loading state until the data is fetched.
 * The selected reference is passed to the parent component via the `setReferentiel` function.
 * 
 * @see {@link https://mui.com/components/selects/} for more information on the MUI Select component.
 */

export default function SelectList({setReferentiel, setRefVersion}:any) {

  const [refDisplay, setRefDisplay] = React.useState('');
  const {search, response, isLoading} = StoreRef();
  let filteredResponse = response?.filter((result) => result.key === 'TAXREF' || result.key === 'GBIF_BBT');
  const {find, refResult} = StoreTaxaVersion();

  useEffect(() => {
    search();
    find();
  }, []);;

  useEffect(() => {
    if (refResult) {
      setRefVersion(refResult.version);
    }
  }), [refResult];

  useEffect(() => {
    if (refDisplay) {
      setReferentiel(refDisplay);
    }
  }, [refDisplay]);

  if (isLoading && !response) {
    return null
  }

  return (
    <Box sx={{ mt: 2, mb: 2, minWidth: 120 }}>
      <FormControl fullWidth>
          <InputLabel>Sélection du référentiel de taxonomie</InputLabel>
          <Select
            value={refDisplay}
            onChange={event => {
              setRefDisplay(event.target.value as string);
              StoreRef.setState({isFinished: true});
            }}
            label = "Sélection du référentiel de taxonomie"
          >
            {response && filteredResponse?.map(result => (
                <MenuItem key={result.key} value={result.key}>
                  {result.name}
                </MenuItem>
            ))}
          </Select>
      </FormControl>
    </Box>
  );
}
import Button from '@mui/material/Button';
import PublishIcon from '@mui/icons-material/Publish';
import { StoreFile } from '../../hooks/store';
import { TerrUnitArray } from '../../utils/types';
import { useEffect } from 'react';
import { errorUtils } from '../../utils/api/configs/axiosConfigs';

/**
 * UploadFile component handles the file upload process.
 *
 * @param {Object} props - The properties object.
 * @param {File} props.file - The file to be uploaded.
 * @param {string} props.referentiel - The reference ID for the upload.
 * @param {TerrUnitArray[]} props.terrUnit - The territorial units associated with the file.
 * @param {number} props.tab - The current tab index to determine the import type.
 * @param {Function} props.setActiveStep - Function to set the active step in the upload process.
 * @param {Function} props.setFileData - Function to set the file data after upload.
 *
 * @returns {JSX.Element} The UploadFile component.
 */

export default function UploadFile({ file, referentiel, terrUnit, tab, setActiveStep, setFileData }: any) {
	const { upload, response } = StoreFile();

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      const importType = tab === 0 ? "biblio" : "status-biblio";
      var terrUnitKey = "";
      formData.append("fileToUpload", file);
      formData.append("refId", referentiel);
      if (terrUnit.length > 0) {
        terrUnitKey = terrUnit.map((unit: TerrUnitArray) => unit.key).join(",");
        formData.append("terrUnits", terrUnitKey);
      } else {
        formData.append("terrUnits", "");
      }
      upload(formData, importType).then(async () => {
        if (errorUtils.lastErrorMessage === "") {
          setActiveStep(1);
        } else {
          console.log(errorUtils.lastErrorMessage);
        }
      });
    }
  };

  useEffect(() => {
    setFileData(response);
  }, [response]);

	return (
    <Button
      startIcon={<PublishIcon />}
      color='primary'
      onClick={handleUpload}
      variant='outlined'
      sx={{ mr: 1 }}
    >
      Importer
    </Button>
	);
}
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { 
  DataGrid, 
  GridRowsProp, 
  GridColDef,
  useGridApiRef
} from '@mui/x-data-grid';
import { StoreStatus } from '../../hooks/store';

/**
 * StatusTable component renders a data grid table with taxon status information.
 * It fetches the status list from the StoreStatus hook and generates unique columns and rows
 * for the data grid. The table includes an export button to download the data as a CSV file.
 *
 * @component
 * @example
 * // Usage example:
 * <StatusTable />
 *
 * @returns {JSX.Element} The rendered StatusTable component.
 *
 * @function
 * @name StatusTable
 *
 * @description
 * This component uses the Material-UI DataGrid to display taxon status information.
 * It dynamically generates columns based on the unique combinations of territorial unit names
 * and status types. Rows are generated based on unique taxon names and their corresponding status values.
 *
 * @hook
 * @name StoreStatus
 * @description Fetches the status list data used to populate the table.
 *
 * @typedef {Object} Status
 * @property {Object} terrUnit - The territorial unit information.
 * @property {string} terrUnit.terrUnitName - The name of the territorial unit.
 * @property {string} terrUnit.terrUnitKey - The key identifier of the territorial unit.
 * @property {Object} statusType - The status type information.
 * @property {string} statusType.shortened - The shortened status type.
 * @property {string} statusType.value - The value of the status type.
 * @property {Object} taxa - The taxon information.
 * @property {string} taxa.txScientificName - The scientific name of the taxon.
 * @property {string} taxa.txKey - The key identifier of the taxon.
 * @property {string} statusValue - The value of the status.
 *
 * @typedef {Object} Column
 * @property {string} terrUnitName - The name of the territorial unit.
 * @property {string} terrUnitKey - The key identifier of the territorial unit.
 * @property {string} statusType - The shortened status type.
 *
 * @typedef {Object} Row
 * @property {string} id - The key identifier of the taxon.
 * @property {string} taxon - The scientific name of the taxon.
 *
 * @param {Status[]} statusList - The list of status objects fetched from the StoreStatus hook.
 * @param {any[]} taxonList - The list of taxon objects to be displayed in the table.
 *
 * @returns {JSX.Element} The rendered StatusTable component.
 */

export default function StatusTable({ taxonList } : any) {

  const { response: statusList } = StoreStatus();
  console.log(statusList);

  function generateUniqueColumns(statusList:any) {
    const columns: any = {};
    statusList.map((status:any) => {
      if (!status.terrUnit || !status.statusType) return;
      const columnKey = `${status.terrUnit.terrUnitName} : ${status.statusType.shortened}`;
      columns[columnKey] = {
        terrUnitName: status.terrUnit.terrUnitName,
        terrUnitKey: status.terrUnit.terrUnitKey,
        statusType: status.statusType.shortened
      };
    });

    const columnArray = Object.values(columns);

    return {
      columns: columnArray
    };
  }

  const headers = (generateUniqueColumns(statusList).columns);

  const stringifyTerrUnitColumn = (headers as { terrUnitName: any, statusType: any }[]).map((column) => {
    return toColumnLabel(column);
  }).sort();

  function toColumnLabel(column: any) {
    return column.terrUnitName + ' : ' + column.statusType;
  }

  const generateUniqueRows = (statusList: any[], columnsTerrUnit: any[]): GridRowsProp => {

    const taxonMap = new Map();

    statusList.map((taxonStatutes) => {
      const txKey = taxonStatutes.taxa.txKey;
      
      // on vérifie si le taxon est déjà dans la map, sinon on l'ajoute avec les valeurs par défaut pour eviter les erreurs de colonnes non définies
      if (!taxonMap.has(txKey)) {
        taxonMap.set(txKey, {
          id: taxonStatutes.taxa.txKey,
          taxon: taxonStatutes.taxa.txScientificName,
          ...Object.fromEntries(columnsTerrUnit.map(col => [toColumnLabel(col), []]))
        });
      }
      
      // on ajoute la valeur du statut dans la colonne correspondante
      columnsTerrUnit.forEach(column => {
        if (column.terrUnitKey === taxonStatutes.terrUnit.terrUnitKey && column.statusType === taxonStatutes.statusType.shortened){
          taxonMap.get(txKey)[toColumnLabel(column)].push(taxonStatutes.statusType.value);
        }
      });
    });

    // on ajoute les taxons sans statuts en bas de la liste
    taxonList.map((taxon: any) => {
      const txKey = taxon.id;
      if (!taxonMap.has(txKey)) {
      taxonMap.set(txKey, {
        id: txKey,
        taxon: taxon.name,
        ...Object.fromEntries(columnsTerrUnit.map(col => [toColumnLabel(col), []]))
      });
      }
    });

    console.log(taxonMap);

    // on formate les listes de statuts en string
    return (Array.from(taxonMap.values()).map((taxon) => {
      return {
        id: taxon.id,
        taxon: taxon.taxon,
        ...Object.fromEntries(columnsTerrUnit.map(col => [toColumnLabel(col), taxon[toColumnLabel(col)].join(', ')]))
      };
    }));
  };

  var rows: GridRowsProp = generateUniqueRows(statusList, headers);

  var columns: GridColDef[] = [
    {
      field: 'taxon',
      headerName: 'Taxon',
      renderHeader: () => (
        <div style={{ 
          fontWeight: 'bold'
        }}>
          {'Taxons'}
        </div>
      ),
      width: 300,
      align: 'left',
      headerAlign: 'left'
    },
    ...stringifyTerrUnitColumn.map((item: any) => (
      {
        field: item, 
        headerName: item, 
        renderHeader: () => (
          <div style={{ 
            fontWeight: 'bold'
          }}>
            {item}
          </div>
        ),
        width: 200
      }))
  ];

  const apiRef = useGridApiRef();

  return (
    <Box
      sx={{
        height: 380,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
        apiRef={apiRef}
      />
      <Box
        sx={{
          display:"flex",
          justifyContent:" center",
          m: 2
        }}
      >
      <Button
        startIcon={<FileDownloadIcon />}
        color='primary'
        onClick={() => apiRef.current.exportDataAsCsv(
          {
            allColumns: true,
            fileName: 'Linnae_status_table',
            delimiter: ';',
          }
        )}
        variant='outlined'
      >
        Exporter
      </Button>
    </Box>
  </Box>
  );
}
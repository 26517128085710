import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import logo from "../../assets/images/logo-biotope.png";
import logoLinnae from "../../assets/images/linnae-logo.png";
import Link from '@mui/material/Link';
import { SignOutButton } from "./SignOutButton";

const NavBar = () => {

    return (
        <>
            <AuthenticatedTemplate>
                <AppBar position="fixed"
                    sx={{
                        zIndex: 10,
                    }}
                >
                    <Toolbar>
                        <Box
                            component="img"
                            sx={{
                                width: "40px",
                                height: "35px",
                                marginRight: "10px",
                            }}
                            src={logoLinnae}
                        />
                        <Link 
                            href={process.env.REACT_APP_LINNAE_URL}
                            underline="none"
                            style={{ flexGrow: 1 }}
                            color="inherit"
                            variant="h6"
                        >
                            Linnae
                        </Link>

                        <SignOutButton />
                        <Box
                            component="img"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                width: "62px",
                                height: "62px",
                            }}
                            src={logo}
                        />
                    </Toolbar>
                </AppBar>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <AppBar position="fixed"
                    sx={{
                        zIndex: 10,
                    }}
                >
                    <Toolbar>
                        <Box
                            component="img"
                            sx={{
                                width: "40px",
                                height: "35px",
                                marginRight: "10px",
                            }}
                            src={logoLinnae}
                        />
                        <Link 
                            href={process.env.REACT_APP_LINNAE_URL}
                            underline="none"
                            style={{ flexGrow: 1 }}
                            color="inherit"
                            variant="h6"
                        >
                            Linnae
                        </Link>
                        <Box
                            component="img"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                                width: "62px",
                                height: "62px",
                            }}
                            src={logo}
                        />
                    </Toolbar>
                </AppBar>
            </UnauthenticatedTemplate>
        </>
    );
};

export default NavBar;